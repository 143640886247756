import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { version as versionApp } from '../../../package.json';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class VersionGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private platform: Platform,
    @Inject(PLATFORM_ID) private readonly platformId: InjectionToken<unknown>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return true; // Evitamos la ejecución en plataformas web
    }

    if (isPlatformBrowser(this.platformId)) {
      return new Observable<boolean>((obs) => {
        this.authService.getVersionApp().subscribe((resp) => {
          const { version } = resp;
          if (version === versionApp) {
            obs.next(true);
          } else {
            this.router.navigate(['/update-version']);
            obs.next(false);
          }
        });
      });
    }

    return false;
  }
}
