import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular';
import { FirestoreService } from '../../services/firestore.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Cliente } from '../../models/cliente.model';
import { promise } from 'protractor';
import { UserI } from 'src/app/models/user.model';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  error: string;
  userId: string;
  private suscriberUserInfo: Subscription = new Subscription();

  cliente: Cliente;
  public status: number;

  public user$: Observable<any>;
  public userData$ = new BehaviorSubject<UserI>(null);

  public user: Observable<UserI>;

  public idUser: string;

  constructor(
    private AFauth: AngularFireAuth,
    private router: Router,
    private toastController: ToastController,
    private afs: AngularFirestore,
    private firestoreService: FirestoreService
  ) {
    this.user$ = this.AFauth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<UserI>(`cliente/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );

    this.getUid();
  }

  async resetPassword(email: string) {
    try {
      return this.AFauth.sendPasswordResetEmail(email);
    } catch (error) {
      console.error('Error', error);
    }
  }

  async register(email: string, password: string): Promise<UserI> {
    try {
      const { user } = await this.AFauth.createUserWithEmailAndPassword(
        email,
        password
      );

      this.updateUser(user);
      await this.sendVerification();

      return user;
    } catch (error) {
      console.error('Error', error);
    }
  }

  async sendVerification(): Promise<void> {
    try {
      return (await this.AFauth.currentUser).sendEmailVerification();
    } catch (error) {
      console.error('Error', error);
    }
  }

  logout() {
    this.AFauth.signOut().then(() => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  public setUserData(user: UserI) {
    this.idUser = user.docRef;
    this.userData$.next(user);
  }

  public getUserData(): Observable<UserI> {
    return this.userData$.asObservable();
  }

  public getVersionApp(): any {
    return this.afs.collection('version').doc('app').valueChanges();
  }

  public getCounterClient() {
    return this.afs.collection('contador').doc('clientes').valueChanges();
  }

  public incrementCounter(count: number) {
    const userAdress = this.afs.collection('contador').doc('clientes');

    // Set the "capital" field of the city 'DC'
    return userAdress
      .update({ total: count })
      .then(() => {
        console.log('count successfully updated!');
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
      });
  }

  async login(email: string, password: string): Promise<UserI> {
    try {
      const { user } = await this.AFauth.signInWithEmailAndPassword(
        email,
        password
      );

      sessionStorage.setItem('userId', user.uid);
      this.userId = user.uid;

      return user;
    } catch (error) {
      console.error('Error', error);
    }
  }

  async logouts(): Promise<void> {
    try {
      await this.AFauth.signOut().then(() => {
        this.router.navigate(['/login']);
      });
    } catch (error) {
      console.error('Error', error);
    }
  }

  public updateUserInfo(user: UserI) {
    const userTemp: AngularFirestoreDocument<UserI> = this.afs.doc(
      `cliente/${this.idUser}`
    );

    return userTemp.update(user).then(() => {
      console.log('order successfully updated!');
    });
  }

  public isEmailVerified(user: UserI): boolean {
    return user.emailVerified === true ? true : false;
  }

  private updateUser(user: UserI) {
    const userTemp: AngularFirestoreDocument<UserI> = this.afs.doc(
      `cliente/${user.uid}`
    );

    const dataUser: UserI = {
      email: user.email,
      docRef: user.uid,
      status: 0,
    };

    return userTemp.set(dataUser, { merge: true });
  }

  public updateDataUser() {
    const user = this.afs.doc<UserI>(`cliente/${this.userId}`).valueChanges();
    user.subscribe((data) => {
      this.setUserData(data);
    });
  }

  public getUser(): Observable<UserI> {
    const user = this.afs.doc<UserI>(`cliente/${this.userId}`).valueChanges();

    return this.afs.doc<UserI>(`cliente/${this.userId}`).valueChanges();
  }

  async obtenerLista() {
    const lista = this.afs.collection('cliente').doc(this.userId);
    const doc = await lista.get();
  }

  registers(data: any, counter?: number) {
    return new Promise((resolve, reject) => {
      this.AFauth.createUserWithEmailAndPassword(data.email, data.password)
        .then((res) => {
          const docRef = res.user.uid;
          this.afs
            .collection('cliente')
            .doc(docRef)
            .set({
              idCliente: counter,
              email: data.email,
              nombreContacto: data.nombreComercial,
              razon: data.razon,
              docRef: docRef,
              nombreComercial: data.pharmacy,
              apellidoMContacto: data.apellidoMContacto,
              apellidoPContacto: data.apellidoPContacto,
              estado: false,
              telefonoCelular: data.telefonoCelular,
              telefonoFijo: data.telefonoFijo,
              docRefDistribuidor: [
                {
                  docRef: 'BMlZRx3lTxOY7WWbVxd3',
                  clave: data.dMarzam,
                  nombre: 'MARZAM',
                },
                {
                  docRef: '3SdaTSa0VQ5vUHQSNGxP',
                  clave: data.dFanasa,
                  nombre: 'FANASA',
                },
              ],
              lstAddress: [],
              favorites: [],
            });
          resolve(res);
        })
        .then(() => {
          this.incrementCounter(counter);
        })
        .catch((err) => reject(err));

      this.AFauth.user.subscribe((x) => {
        if (x) {
          x.sendEmailVerification()
            .then(() => {
              console.log('Email verification sent');
            })
            .catch((err) => {
              console.log('Error: ', err);
            });
        }
      });
    });
  }

  async sendEmailLink(email: string) {
    const actionCodeSettings = {
      url: 'http://localhost:8100/login',
      handleCodeInApp: true,
    };
    try {
      await this.AFauth.sendSignInLinkToEmail(email, actionCodeSettings);
      console.log('Enviando correo verificación');
    } catch (err) {
      this.error = err.message;
    }
  }

  async getUid() {
    const user = await this.AFauth.currentUser;

    if (user === null) {
      return null;
    } else {
      this.userId = user.uid;
      localStorage.setItem('userId', user.uid);
      await this.getUserInfo(user.uid);
      return user.uid;
    }
  }

  async getUserInfo(uid: string) {
    const path = 'cliente';
    this.suscriberUserInfo = await this.firestoreService
      .getDoc<Cliente>(path, uid)
      .subscribe((res) => {
        this.cliente = res;
        return this.cliente;
      });
  }

  async confirmSignIn(url: string) {
    try {
      if (this.AFauth.isSignInWithEmailLink(url)) {
        let email = window.localStorage.getItem('signInEmail');
        if (!email) {
          email = window.prompt('Confirma tu correo por favor :c');
        }
        const result = await this.AFauth.signInWithEmailLink(email, url);
        if (result) {
          this.router.navigate(['/login']);
          //clean localStorage
          window.localStorage.removeItem('signInEmail');
        } else {
          console.log('an error ocurred');
        }
      }
    } catch (err) {
      this.error = err.message;
    }
  }
  //estado de autenticacion
  stateAuth() {
    return this.AFauth.authState;
  }

  public forgotPassoword(email: string) {
    this.AFauth.sendPasswordResetEmail(email)
      .then(async () => {
        const toast = await this.toastController.create({
          header: 'OTC Senosiain',
          message: 'Correo de recuperación de contraseña enviado.',
          position: 'bottom',
          buttons: [
            {
              text: 'aceptar',
              role: 'cancel',
              handler: () => {},
            },
          ],
        });
        toast.present();
      })
      .catch(async (err) => {
        if (err.code == 'auth/invalid-email') {
          const toast = await this.toastController.create({
            header: 'OTC Senosiain.',
            message: 'Ingrese un email con formato valido',
            position: 'bottom',
            buttons: [
              {
                text: 'aceptar',
                role: 'cancel',
                handler: () => {},
              },
            ],
          });
          toast.present();
        } else if (err.code == 'auth/user-not-found') {
          const toast = await this.toastController.create({
            header: 'OTC Senosiain.',
            message: 'No se encontró ninguna cuenta con el email ingresado',
            position: 'bottom',
            buttons: [
              {
                text: 'aceptar',
                role: 'cancel',
                handler: () => {},
              },
            ],
          });
          toast.present();
        } else {
          const toast = await this.toastController.create({
            header: 'OTC Senosiain.',
            message: 'error desconocido',
            position: 'bottom',
            buttons: [
              {
                text: 'aceptar',
                role: 'cancel',
                handler: () => {},
              },
            ],
          });
          toast.present();
        }
      });
  }
}
