import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-anotherheader',
  templateUrl: './anotherheader.component.html',
  styleUrls: ['./anotherheader.component.scss'],
})
export class AnotherheaderComponent implements OnInit {
  @Input() arrow: boolean;

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

}
