export interface Cliente {
  docRef: string;
  id: string;
  nombreContacto: string;
  email: string;
  phoneNumber: string;
  born: string;
  lastNamePat: string;
  lastNameMat: string;
  docRefDistribuidor2: string;
  docRefDistribuidor1: string;
  docRefDistribuidor: [];
}
export interface Profile {
  docRef: string;
  id: string;
  nombreComercial: string;
  razon: string;
  nombreContacto: string;
  apellidoPContacto: string;
  apellidoMContacto: string;
  telefonoFijo: string;
  telefonoCelular: string;
  favorites: string[];
  
  docRefDistribuidor: Dealer[];
}
export class User {
  docRef: string;
  id: string;
  nombreComercial: string;
  razon: string;
  nombreContacto: string;
  apellidoPContacto: string;
  apellidoMContacto: string;
  telefonoFijo: string;
  telefonoCelular: string;
  favorites: string[];
  //docRefRepresentante: string;
  docRefDistribuidor2: string;
  docRefDistribuidor1: string;
  docRefDistribuidor: [
    {
      clave: String;
      docRef: String;
    },
    {
      clave: String;
      docRef: String;
    }
  ];

  constructor() {
    this.id = this.id;
    this.docRef = this.docRef;
    this.nombreComercial = this.nombreComercial;
    this.razon = this.razon;
    this.nombreContacto = this.nombreContacto;
    this.apellidoPContacto = this.apellidoPContacto;
    this.apellidoMContacto = this.apellidoMContacto;
    this.telefonoFijo = this.telefonoFijo;
    this.telefonoCelular = this.telefonoCelular;
    this.docRefDistribuidor = this.docRefDistribuidor;
    this.docRefDistribuidor1 = this.docRefDistribuidor1;
  }
}

export interface UserI {
  uid?: string;
  idCliente?: number;
  email?: string;
  docRef?: string;
  status?: number;
  nombreContacto?: string;
  apellidoPContacto?: string;
  apellidoMContacto?: string;
  telefonoFijo?: string;
  telefonoCelular?: string;
  nombreComercial?: string;
  razon?: string;
  emailVerified?: boolean;
  favorites?: string[];
  docRefRepresentante?: string;
  docRefDistribuidor?: Dealer[];
}

export interface Dealer{
  clave: string;
      docRef: string;
      nombre: string;
}
