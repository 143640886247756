import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { HeadergeneralComponent } from "./headergeneral/headergeneral.component";
import { ToolBarComponent } from "./tool-bar/tool-bar.component";
import { IonicModule } from "@ionic/angular";
import { PopinfoComponent } from "./popinfo/popinfo.component";
import { FooterComponent } from "./footer/footer.component";
import { AnotherheaderComponent } from "./anotherheader/anotherheader.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    HeaderComponent,
    HeadergeneralComponent,
    PopinfoComponent,
    FooterComponent,
    AnotherheaderComponent,
    ToolBarComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  exports: [
    HeaderComponent,
    HeadergeneralComponent,
    PopinfoComponent,
    FooterComponent,
    AnotherheaderComponent,
    ToolBarComponent,
  ],
})
export class ComponentsModule {}
