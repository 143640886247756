import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
//import { PopoverComponent } from '../../component/popover/popover.component';
import { PopinfoComponent } from '../popinfo/popinfo.component';
import { AuthService } from '../../services/auth/auth.service';
import { ShopService } from 'src/app/services/shop/shop.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-headergeneral',
  templateUrl: './headergeneral.component.html',
  styleUrls: ['./headergeneral.component.scss'],
})
export class HeadergeneralComponent{
  public selectedPath: string;
  public pages = [];
  cntrProducts: BehaviorSubject<number>;
  @ViewChild('menu', { static: false, read: ElementRef }) menuItems: ElementRef;

  @Input() tituloA: String;
  constructor(private router: Router, 
    public popoverController: PopoverController,
    public authservice : AuthService,
    public shopService : ShopService,
    private util: UtilService,
    ) {    
      this.selectedPath = this.router.url;
      this.pages = [
        { title: 'Inicio', url: '/home' },
        { title: 'Mi Perfil', url: '/profile/personal' },
        { title: 'Mis favoritos', url: '/profile/favorites' },
        { title: 'Mi historial', url: '/profile/history' },
        { title: 'Cerrar sesión', url:'/login'}
      ];
    }
    ngOnInit() {
      document.getElementById("mobile-items").style.display = "none";
    }
  
    navigateto(url: string) {
      document.getElementById("mobile-items").style.display = "none";
      this.router.navigate([url]);
    }
  
    menuAction() {
      if (document.getElementById("mobile-items").style.display == "block") {
        this.util.animateCSS(this.menuItems, "fadeOut");
        document.getElementById("mobile-items").style.display = "none";
      } else {
        document.getElementById("mobile-items").style.display = "block";
        this.util.animateCSS(this.menuItems, "fadeIn");
      }
    }

    routerPage(valor: string ) {
      this.router.navigateByUrl('/'+valor);
  }

  async mostrarPop( evento ){
    const popover = await this.popoverController.create({
      component: PopinfoComponent,
      event: evento,
      mode: 'ios'
    });

    await popover.present();
  }

  public openPage( valor: string ){
    this.router.navigate(['/'+valor]);
  }

  Onlogout(){
    this.authservice.logout();
  } 

}
