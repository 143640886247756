import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PostalCode } from 'src/app/models/postalCode.model';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  constructor(public afs: AngularFirestore) {}

  async getAgent(postalCode: string) {
    return this.afs
      .collection('cpRepresentante', (ref) =>
        ref.where('codigoPostal', '==', postalCode)
      )
      .get()
      .toPromise()
      .then((querySnapshot) => {
        return querySnapshot.docs.map((doc) => doc.data());
      });
  }

  public getAgentById(id: string) {
    return this.afs.collection('representante').doc(id).valueChanges();
  }
}
