import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
//mport { HttpModule } from "@angular/http";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//import { IonicStorageModule } from '@ionic/storage';

// import firebaseConfig from './firebase';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ShopService } from './services/shop/shop.service';
import { from } from 'rxjs';
import { ComponentsModule } from './components/components.module';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { OrderUService } from './services/order/order-u.service';
import { AddressService } from './services/address/address.service';
import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { AuthModule } from './pages/auth/auth.module';
import { FormatNumberPipe } from './shared/pipes/format-number.pipe';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ComponentsModule,
    UserProfileModule,
    AuthModule,
    // HttpModule
  ],

  providers: [
    ShopService,
    StatusBar,
    SplashScreen,
    OrderUService,

    AddressService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
