import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    
    private router: Router,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: InjectionToken<unknown>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      return this.authService.user$.pipe(
        take(1),
        map((user) => {
          if (user) {
            this.authService.setUserData(user)
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        })
      );
    }
  }
}
