import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from as fromPromise } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  public getLocation(cp: string): Observable<any> {
    return this.http
      .get<any>(`${environment.APICP}` + cp + `${environment.TOKEN}`)
      .pipe(
        map((suburbs) => {
          let n = 0;
          const dataSuburb = [];
          suburbs.map((colonia) => {
            const col = {
              id: n,
              ...colonia.response,
            };
            dataSuburb.push(col);
            n++;
          });
        

          return dataSuburb;
        })
      );
  }
}
