import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShopService } from 'src/app/services/shop/shop.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  cntrProducts: BehaviorSubject<number>;
  @Input() titulo: String;
  constructor(
    public shopService: ShopService

  ) {
   
   }

  ngOnInit() {
    this.cntrProducts = this.shopService.getCartItemCount();
  }

}
