import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { UserProfileComponent } from "./user-profile.component";

const routes: Routes = [
  {
    path: "",
    component: UserProfileComponent,
    children: [
      {
        path: "address",
        loadChildren: () =>
          import("./components/address/address.module").then(
            (m) => m.AddressPageModule
          ),
      },
      {
        path: "history",
        loadChildren: () =>
          import("./components/history/history.module").then(
            (m) => m.HistoryPageModule
          ),
      },
      {
        path: "personal",
        loadChildren: () =>
          import("./components/profile/profile.module").then(
            (m) => m.ProfilePageModule
          ),
      },
      {
        path: "favorites",
        loadChildren: () =>
          import("./components/favorite/favorite.module").then(
            (m) => m.FavoritePageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
