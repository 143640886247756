import { Product } from './product.model';
import { Address } from './address.model';

export interface OrderProduct {
  id?: string;
  nombre?: number;
  descripcion?: string;
  disponibilidad?: boolean;
  division?: string;
  docRefCategoria?: string;
  promocion?: string;
  sku?: string;
  unidad?: string;
  urlImagen?: string;
  docRef?: string;
  promoCompra?: number;
  promoGratis?: number;
  totalPromo?: number;
  totalMeds?: number;
  subTotal?: number;
}

export class Order {
  numPedido: string;
  total?: number;
  deliveryTimeRange: string;
  lstProducts?: OrderProduct[];
  address: Address;
  docRef: string;
  dateCreated: string;
  docRefStatus: string;
  docRefRepresentante: string;
  open?: boolean;
  fechaConfirmacion?: string;
  construtor() {
    this.numPedido = this.numPedido;
    this.total = this.total;
    this.lstProducts = new Array<OrderProduct>();
    this.address = new Address();
    this.docRef = this.docRef;
    this.dateCreated = this.dateCreated;
    this.docRefStatus = this.docRefStatus;
    this.docRefRepresentante = this.docRefRepresentante;
    this.open = this.open;
    this.fechaConfirmacion = this.fechaConfirmacion;
  }
}
