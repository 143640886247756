import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(public loadingController: LoadingController) {}

  // Simple loader
  public showLoader() {
    this.loadingController
      .create({
        message: 'Espere un momento...',
        cssClass: 'loader-css-class',
        translucent: true,
      })
      .then((response) => {
        response.present();
      });
  }

  // Dismiss loader
  public hideLoader() {
    this.loadingController
      .dismiss()
      .then((response) => {
        console.log('Loader closed!', response);
      })
      .catch((err) => {
        console.log('Error occured : ', err);
      });
  }

  // Auto hide show loader
  public autoLoader() {
    this.loadingController
      .create({
        message: 'Loader hides after 4 seconds',
        duration: 4000,
      })
      .then((response) => {
        response.present();
        response.onDidDismiss().then((response) => {
          console.log('Loader dismissed', response);
        });
      });
  }

  // Custom style + hide on tap loader
  public customLoader() {
    this.loadingController
      .create({
        message: 'Loader with custom style',
        duration: 4000,
        cssClass: 'loader-css-class',
        backdropDismiss: true,
      })
      .then((res) => {
        res.present();
      });
  }
}
