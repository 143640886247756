import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Order } from 'src/app/models/order.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { StatusOrder } from 'src/app/models/status.model';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root',
})
export class OrderUService {
  orderU: Observable<Order[]>;
  stado: Observable<StatusOrder[]>;
  userId: string;
  constructor(
    private AFauth: AngularFireAuth,
    private firestoreService: FirestoreService,
    public afs: AngularFirestore
  ) {}

  public getOrders(idUser: string): Observable<Order[]> {
    return this.afs
      .collection<Order>('order', (ref) => ref.where('docRef', '==', idUser))
      .valueChanges({ idField: 'numPedido' })
      .pipe(
        map((items) =>
          items.sort(
            (a, b) =>
              new Date(b.dateCreated).getTime() -
              new Date(a.dateCreated).getTime()
          )
        )
      );
  }

  public getOrderU() {
    this.orderU = this.afs
      .collection('order')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Order;
            data.docRef = a.payload.doc.id;
            return data;
          })
        )
      );
    return this.orderU;
  }

  public getStatus(idState: string) {
    return this.afs
      .collection<StatusOrder>('status', (ref) =>
        ref.where('id', '==', idState)
      )
      .valueChanges();
  }

  public updateOrder(order: Order, status: string, date?: string) {
    var orderFire = this.afs.collection('order').doc(order.numPedido);
    let orderUpdtate = { docRefStatus: status };

    if (date) {
      orderUpdtate['fechaConfirmacion'] = date;
    }

    return orderFire
      .update(orderUpdtate)
      .then(() => {
        console.log('order successfully updated!');
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
      });
  }
}
