import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UserProfileRoutingModule } from "./user-profile-routing.module";
import { ComponentsModule } from "src/app/components/components.module";
import { IonicModule } from "@ionic/angular";
import { UserProfileComponent } from "./user-profile.component";

@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    IonicModule,
    ComponentsModule,
  ],
})
export class UserProfileModule {}
