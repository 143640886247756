import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth.component";

const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./components/login/login.module").then(
            (m) => m.LoginPageModule
          ),
      },
      {
        path: "register",
        loadChildren: () =>
          import("./components/register/register.module").then(
            (m) => m.RegisterPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
