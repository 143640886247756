export interface AddressInterface {
    docRefUser?: string;
    id: number;
    nombre: string;
    calle: string;
    colonia: string;
    ciudad: string;
    noExterior: string;
    noInterior?: string;
    cp: string;
    alcaldia: string;
    pais: string;
}

export class Address {
    docRefUser?: string;
    id: string;
    nombre: string;
    calle: string;
    colonia: string;
    ciudad: string;
    noExterior: string;
    noInterior?: string;
    cp: string;
    alcaldia: string;
    pais: string;

    constructor(newAddress?: Address) {
        if (newAddress) {
            this.docRefUser = newAddress.docRefUser;
            this.id = newAddress.id;
            this.nombre = newAddress.nombre;
            this.calle = newAddress.calle;
            this.colonia = newAddress.colonia;
            this.ciudad = newAddress.ciudad;
            this.noExterior = newAddress.noExterior;
            this.noInterior = newAddress.noInterior;
            this.cp = newAddress.cp;
            this.alcaldia = newAddress.alcaldia;
            this.pais = newAddress.pais;
        }
    }
}

export interface Location {
    descripcion?: string;
    packProductos?: string;
    paquete?: boolean;
    publicado?: boolean;
    urlImagen?: string;
}
