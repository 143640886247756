import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-popinfo',
  templateUrl: './popinfo.component.html',
  styleUrls: ['./popinfo.component.scss'],
})
export class PopinfoComponent {
  constructor(
    private router: Router,
    public authservice: AuthService,
    private popoverController: PopoverController
  ) {}

  public routerPage(valor: string) {
    this.router.navigateByUrl('/' + valor);
    this.closePop();
  }

  public onLogout() {
    this.authservice.logout();
    this.closePop();
  }

  private closePop() {
    this.popoverController.dismiss();
  }
}
