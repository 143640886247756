import { Component } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from '../app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Navigate } from './models/navigate.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public navigates: Navigate[];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    this.initializeApp();
    this.sideMenu();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  /**
   * @description set items for menu hamburger
   */
  private sideMenu() {
    this.navigates = [
      {
        title: 'Inicio',
        url: '/home',
        icon: 'home',
      },
      {
        title: 'Mis domicilios',
        url: '/profile/address',
        icon: 'location',
      },
      {
        title: 'Mis pedidos',
        url: '/profile/history',
        icon: 'receipt',
      },
      {
        title: 'Mis favoritos',
        url: '/profile/favorites',
        icon: 'heart',
      },
      {
        title: 'Mis datos',
        url: '/profile/personal',
        icon: 'person',
      },
      {
        title: 'Cerrar sesión',
        url: '/login',
        icon: 'exit',
      },
      {
        title: 'Aviso de privacidad',
        url: '/notice-privacy',
        icon: 'document',
      },
    ];
  }

  public navigatePage(route: string) {
    if (route === '/login') {
      this.authService.logout();
    }

    this.router.navigateByUrl(route);
  }

  //Este bloque estaba por default, se agregó el bloque de arriba para poder iniciar la app en register
  //initializeApp() {
  //this.platform.ready().then(() => {
  //this.statusBar.styleDefault();
  //this.splashScreen.hide();
  // });
  // }
}
