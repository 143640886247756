import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class FirestoreService {
    constructor(public database: AngularFirestore){}

    creatDoc(data: any, path : string, id : string){
       const collection = this.database.collection(path)
       return collection.doc(id).set(data);
    }
//permite obtener los documentos de la base de datos
    getDoc<tipo>(path : string, id : string){
        const collection = this.database.collection<tipo>(path)
        return collection.doc(id).valueChanges();
    }

    deleteDoc(path : string, id : string){
        const collection = this.database.collection(path)
        return collection.doc(id).delete();
    }

    updateDoc(data : any, path : string, id : string){
        const collection = this.database.collection(path)
        return collection.doc(id).update(data);
    }

    getId(){
        return this.database.createId();
    }

    getCollection<tipo>(path : string){
        const collection = this.database.collection<tipo>(path);
        return collection.valueChanges();
    }
}