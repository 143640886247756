import { Injectable } from '@angular/core';
import { Order, OrderProduct } from 'src/app/models/order.model';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/models/product.model';
import { catchError, map, switchMap, take, timeout } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { isNullOrUndefined } from 'util';
import { Address } from '../../models/address.model';

import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { AgentService } from '../agent/agent.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  productsCollections: AngularFirestoreCollection<Product>;
  products: Observable<Product[]>;
  order: Order;
  cntrProducts: BehaviorSubject<number>;
  lstProducts: Product[];
  URL =
    'https://0ri34agq71.execute-api.us-east-1.amazonaws.com/v1/product/sendOrder';

  constructor(
    private http: HttpClient,
    public afs: AngularFirestore,
    private router: Router,
    private alertController: AlertController,
    private authService: AuthService,
    public userService: UserService,
    private readonly agentServices: AgentService,
    private readonly loaderService: LoaderService
  ) {
    this.cleanShopService();
  }
  public cleanShopService() {
    this.cntrProducts = new BehaviorSubject(0);
    this.order = new Order();
    this.order.lstProducts = new Array<OrderProduct>();
    this.order.address = new Address();
    //this.order.address = new Address();
  }

  public getProducts() {
    this.products = this.afs
      .collection('producto')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const products = actions.map((a) => {
            const data = a.payload.doc.data() as Product;
            data.id = a.payload.doc.id;

            return data;
          });

          return products.sort(
            (x, y) => 0 - (x.prioridad > y.prioridad ? -1 : 1)
          );
        })
      );

    return this.products;
  }

  public addToCar(_product) {
    var newProduct = _product as OrderProduct;
    let exist: number = this.order.lstProducts.filter(
      (product) => product.id == newProduct.id
    ).length;
    if (exist > 0) {
      let position: number = this.order.lstProducts.findIndex(
        (product) => product.id == newProduct.id
      );
      this.order.lstProducts[position].totalMeds++;
    } else {
      newProduct.totalMeds = 1;
      this.order.lstProducts.push(newProduct);
      this.cntrProducts.next(this.cntrProducts.value + 1);
    }
    this.calculateSubtotal();
  }

  public decreaseProduct(product: OrderProduct) {
    this.order.lstProducts.forEach((p) => {
      if (p.id === product.id) {
        p.totalMeds -= 1;
        if (p.totalMeds == 0) {
          this.removeProduct(product);
        }
      }
    });
    this.calculateSubtotal();
  }

  public removeProduct(product: OrderProduct) {
    var index: number = 0;
    this.order.lstProducts.forEach((p) => {
      if (p.id == product.id) {
        this.cntrProducts.next(this.cntrProducts.value - 1);
        this.order.lstProducts.splice(index, 1);
      }
      index++;
    });
    this.calculateSubtotal();
  }

  public inOrder(id: string, lstProducts: Array<OrderProduct>): boolean {
    var inOrder = false;
    if (!isNullOrUndefined(lstProducts)) {
      const exist: number = lstProducts.filter(
        (orderProduct) => id == orderProduct.id
      ).length;
      if (exist > 0) {
        inOrder = true;
      }
    }
    return inOrder;
  }

  public calculateSubtotal() {
    this.order.lstProducts.map((product) => {
      var subtotal = 0;
      if (product.promoCompra) {
        product.totalPromo = Math.trunc(
          (product.totalMeds / product.promoCompra) * product.promoGratis
        );
        subtotal = product.totalPromo + product.totalMeds;
      } else {
        subtotal = product.totalMeds;
        product.totalPromo = 0;
      }
      product.subTotal = Math.round(subtotal * 100) / 100;
    });

    this.order.total = this.order.lstProducts.reduce((total, product) => {
      return Math.round((total + product.subTotal) * 100) / 100;
    }, 0);
  }

  public getCartItemCount() {
    return this.cntrProducts;
  }

  public async sendOrder() {
    try {
      const docRefRepresentante = await this.getDocRefByZipCode(
        this.order.address.cp
      );

      let agentData = null;

      if (docRefRepresentante) {
        agentData = await this.getRepresentativeData(docRefRepresentante);
        this.order.docRefRepresentante = agentData?.docRef || null;
        this.order.docRefStatus = '2';
      } else {
        this.order.docRefStatus = '1';
        this.order.docRefRepresentante = '';
      }

      const docRef = await this.addOrder();

      const emailAgent = agentData?.email || '';

      this.sendEmail(emailAgent, docRef.id);
    } catch (error) {
      console.error('Error en el proceso de envío de orden: ', error);
      this.loaderService.hideLoader();
      this.openAlert(
        'Error de pedido',
        'Ocurrió un error, póngase en contacto con el proveedor.'
      );
    } finally {
      this.cleanShopService();
    }
  }

  private async addOrder(): Promise<any> {
    return await this.afs
      .collection('order')
      .add(Object.assign({}, this.order));
  }

  private async getDocRefByZipCode(
    codigoPostal: string
  ): Promise<string | null> {
    try {
      const result = await this.afs
        .collection('cpRepresentante', (ref) =>
          ref.where('codigoPostal', '==', codigoPostal)
        )
        .snapshotChanges()
        .pipe(
          timeout(10000),
          map((actions) => {
            if (actions.length === 0) {
              throw new Error('Código postal no encontrado');
            }
            const data = actions[0].payload.doc.data() as any;
            return data?.docRefRepresentante || null;
          }),
          take(1)
        )
        .toPromise();

      return result;
    } catch (error) {
      console.error('Error obteniendo el docRefRepresentante:', error);
      return null;
    }
  }

  private async getRepresentativeData(
    docRefRepresentante: string
  ): Promise<any> {
    try {
      const representative = await this.afs
        .doc(`representante/${docRefRepresentante}`)
        .valueChanges()
        .pipe(timeout(10000), take(1))
        .toPromise();

      if (!representative) {
        throw new Error('Datos del representante no encontrados');
      }

      return representative;
    } catch (error) {
      console.error('Error obteniendo los datos del representante:', error);
      return null;
    }
  }

  public sendEmail(emailRepresentante: string, orderId: string) {
    const json_object = {
      to: `${this.authService.cliente.email}, pedidos@otcsenosiain.mx, ${emailRepresentante}`,
      subject: 'Detalle de orden',
      user: this.authService.cliente.nombreContacto,
      userId: this.authService.cliente.idCliente,
      bodyLink: '',
      order: {
        address: {
          city: this.order.address.ciudad,
          outdoorExt: this.order.address.noExterior,
          outdoorInt: this.order.address.noInterior,
          postalCode: this.order.address.cp,
          street: this.order.address.calle,
          suburb: this.order.address.colonia,
          townHall: this.order.address.alcaldia,
        },
        dateCreated: this.order.dateCreated,
        deliveryTimeRange: this.order.dateCreated,
        docRef: orderId,
        docRefStatus: this.order.docRefStatus ?? '1',
        lstProducts: this.order.lstProducts,
        total: this.order.total,
      },
    };
    debugger;
    this.http.post(this.URL, json_object).subscribe(
      async (res) => {
        this.loaderService.hideLoader();
        this.openAlert(
          'Pedido exitoso',
          'Tu pedido se ha completado, favor de verificar la recepción del correo con los detalles del pedido.'
        );
      },
      (err) => {
        console.error('Error al enviar el correo: ', err);
        this.loaderService.hideLoader();
        this.openAlert(
          'Error de correo',
          'No se pudo enviar el correo, por favor inténtelo más tarde.'
        );
      }
    );
  }

  private async openAlert(title: string, subTitle: string) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: title,
      message: subTitle,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            this.router.navigateByUrl('/home');
          },
        },
      ],
    });
    await alert.present();
  }
}
