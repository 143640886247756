import { Injectable } from '@angular/core';
import { Profile, User } from 'src/app/models/user.model';
import { Address } from 'src/app/models/address.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Cliente } from 'src/app/models/cliente.model';
import { AuthService } from '../../services/auth/auth.service';
import { FirestoreService } from '../firestore.service';
import firebase from 'firebase/app';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  cliente: Cliente;
  address: Observable<Address[]>;
  addressTemp: Address;
  docRefUser: string;
  user: User;
  public userProfile: any;
  lstAddress: Address[] = new Array<Address>();
  lstFavorites: Product[] = new Array<Product>();
  lstHistory: any;
  isLoged: boolean;
  favorites: string[];
  favoritesCntr: BehaviorSubject<number>;
  userId: string;

  constructor(
    public afs: AngularFirestore,
    private http: HttpClient,
    private fireAuth: AngularFireAuth,
    private db: AngularFirestore,
    public authService: AuthService,
    private readonly firestoreService: FirestoreService,
    private readonly loaderService: LoaderService
  ) {
    this.userId = localStorage.getItem('userId');
  }

  public getUser(): Observable<any> {
    return this.fireAuth.authState.pipe(
      map((a) => {
        const data = a as unknown as User;
        this.favorites = data.favorites;
        return data;
      })
    );
  }

  public getU() {
    return this.userProfile;
  }

  public getProfile() {
    return this.db.collection('cliente').doc(this.userId).valueChanges();
  }

  public setUser(firebaseToken: string): Observable<any> {
    return this.http.get('/user?firebaseToken=' + firebaseToken).pipe(
      map((results) => {
        return results;
      })
    );
  }

  /**
   * updata data user
   *
   * @param user
   * @param collection
   * @returns
   */
  public updateUser(user: Cliente, collection: string): Promise<any> {
    return this.firestoreService
      .updateDoc(JSON.parse(JSON.stringify(user)), collection, user.docRef)
      .then((data) => {
        localStorage.setItem('user', JSON.stringify(this.cliente));
      })
      .catch((err) => console.error(err));
  }

  public cleanUserService() {
    this.user = new User();
    this.addressTemp = new Address();
    this.lstFavorites = new Array<Product>();
    this.favoritesCntr = new BehaviorSubject(0);
  }

  public updateProfile(userId: string, profile: Profile) {
    return this.firestoreService
      .updateDoc(JSON.parse(JSON.stringify(profile)), 'cliente', userId)
      .then(() => console.log('sdsd'))
      .catch((err) => console.error(err));
  }

  public getAddressUserss(idUser: string): Observable<Address[]> {
    return this.afs
      .collection<Address>('address', (ref) =>
        ref.where('docRef', '==', idUser)
      )
      .valueChanges({ idField: 'numPedido' });
  }

  //CRUD address
  public newAddressUser(userId: string, address: Address, id: number) {
   
    this.afs
      .collection('cliente')
      .doc(userId)
      .collection('address')
      .doc(id.toString())
      .set(Object.assign({}, address))
      .then((docRef) => {
        this.loaderService.hideLoader();
        console.log('Document written with ID: ', docRef);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  }

  public getAddressUser(userId: string) {
    return this.db
      .collection('cliente')
      .doc(userId)
      .collection('address')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          })
        )
      );

    /*return this.afs.collection('user')
      .doc('zGuK7SZcXaeK6XAt1Kfb')
      .collection('address')
      .get()

    return this.afs.collection('user').
      doc('zGuK7SZcXaeK6XAt1Kfb').
      collection('address').doc("12").valueChanges()
      ;*/
  }

  public updateAddressUser(userId: string, address: Address) {
    var userAdress = this.db
      .collection('cliente')
      .doc(userId)
      .collection('address')
      .doc(address.id);

    // Set the "capital" field of the city 'DC'
    return userAdress
      .update(Object.assign({}, address))
      .then(() => {
        this.loaderService.hideLoader();
        console.log('Document successfully updated!');
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
      });
  }

  public deleteAddressUser(userId: string, addressId: string) {
    return this.db
      .collection('cliente')
      .doc(userId)
      .collection('address')
      .doc(addressId)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  }

  public updateAgentUser(userId: string, agentId: string) {
    var userAdress = this.db.collection('cliente').doc(userId);

    // Set the "capital" field of the city 'DC'
    return userAdress
      .update({ docRefRepresentante: agentId })
      .then(() => {
        console.log('agent successfully updated!');
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
      });
  }

  //CRUD Favorites
  public getFavorite(idUser: string): Observable<Address[]> {
    return this.afs
      .collection<Address>('cliente', (ref) =>
        ref.where('docRef', '==', idUser)
      )
      .valueChanges({ idField: 'numPedido' });
  }

  public addFavorite(productId: string) {
    if (!this.authService.cliente.favorites.includes(productId)) {
      this.afs
        .collection('cliente')
        .doc(this.userId)
        .update({
          favorites: firebase.firestore.FieldValue.arrayUnion(productId),
        })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    } else {
      this.afs
        .collection('cliente')
        .doc(this.userId)
        .update({
          favorites: firebase.firestore.FieldValue.arrayRemove(productId),
        })
        .then((docRef) => {
          console.log('remove ', docRef);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    }
  }

  //Direcciones
  public newAddressz(newAddress: Address): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection('address')
        .add(Object.assign({}, newAddress))
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    });
  }

  public newAddress(newAddress: Address, agent: string): Promise<any> {
    this.cliente = JSON.parse(localStorage.getItem('user'));
    this.cliente.docRefRepresentante = agent;
    this.cliente.lstAddress.push(Object.assign({}, newAddress));

    return new Promise<any>((resolve, reject) => {
      this.afs
        .collection('cliente')
        .doc(this.cliente.docRef)
        .set(Object.assign({}, JSON.parse(JSON.stringify(this.cliente))))
        .then(() => {
          localStorage.setItem('user', JSON.stringify(this.cliente));
        });
    });
  }

  stateAuth() {
    return this.fireAuth.authState;
  }

  public getAddress(userId: string) {
    return this.afs
      .collection('address', (ref) => ref.where('docRefUser', '==', userId))
      .valueChanges();
  }
  obtenerDir<tipo>(path) {
    const doc: AngularFirestoreDocument<tipo> = this.db.doc(path);
    return doc.valueChanges();
  }

  public getAddressUsers() {
    this.address = this.db
      .collection('cliente')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Address;
            data.docRefUser = a.payload.doc.id;
            return data;
          })
        )
      );
    return this.address;
  }

  async getdocRefUser() {
    const userAddres = await this.fireAuth.currentUser;
    if (userAddres === null) {
      return null;
    } else {
      this.docRefUser = userAddres.uid;
      return userAddres.uid;
    }
  }

  async getFavoritos() {
    return new Promise(async (resolve, reject) => {
      let userID = await this.getdocRefUser();
      let docRef = this.db.collection('favoritos').doc(userID);
      docRef
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            let resultados = doc.data()['favoritos'].map((obj) => {
              return obj as Product;
            });
            resolve(resultados);
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    });
  }

  public async favorite(idUser: string, producto: Product) {
    let favoritos = (await this.getFavoritos()) as Product[];
    favoritos.push(producto);
    this.db.collection('favoritos').doc(idUser).set({ favoritos: favoritos });
    this.lstFavorites = (await this.getFavoritos()) as Product[];
  }

  async deleteFavorite(sku: string) {
    let favoritos = (await this.getFavoritos()) as Product[];
    let userID = await this.getdocRefUser();
    let index = favoritos.findIndex((elem) => {
      if (sku === elem.sku) {
        return true;
      }
      return false;
    });
    favoritos.splice(index, 1);
    this.db.collection('favoritos').doc(userID).set({ favoritos: favoritos });
    this.lstFavorites = (await this.getFavoritos()) as Product[];
  }

  async existsFavoritos(product: Product) {
    let favoritos = (await this.getFavoritos()) as Product[];
    favoritos = favoritos.filter((elem: Product) => {
      if (elem.docRef === product.docRef) {
        return true;
      } else return false;
    });
    return favoritos.length > 0;
  }

  public async addFavorites(product: Product) {
    var userID = await this.getdocRefUser();
    let exist = await this.existsFavoritos(product);
    if (!exist) {
      this.favorite(userID, product);
    }
  }

  public isFavorite(productFav): boolean {
    let newFavorite = productFav as Product;
    let exist: number =
      this.lstFavorites &&
      this.lstFavorites.filter((idProduct) => productFav.id == idProduct)
        .length;
    if (exist > 0) {
      return true;
    } else {
      return false;
    }
  }
}
