import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { NologinGuard } from './guards/nologin.guard';
import { RegisterGuard } from './guards/register.guard';
import { VersionGuard } from './guards/version-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard, VersionGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth-routing.module').then(
        (m) => m.AuthRoutingModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule), //,
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/final-register/final-register.module').then(
        (m) => m.FinalRegisterModule
      ),
    canActivate: [RegisterGuard],
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartPageModule),
    canActivate: [LoginGuard],
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'notice-privacy',
    loadChildren: () =>
      import('./pages/notice-privacy/notice-privacy.module').then(
        (m) => m.NoticePrivacyModule
      ),
  },
  {
    path: 'update-version',
    loadChildren: () =>
      import('./pages/update-version/update-version.module').then(
        (m) => m.UpdateVersionModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
